import React from 'react';
import { IconButton, IconButtonPage, Layout } from '../../components/onboarding';
import PURCHASE from '../../images/onboarding/purchase.svg';
import REFINANCE from '../../images/onboarding/refinance.svg';
import { my } from '../../modules/navigation';

const title = 'What brings you here today?';

const GetStarted = () => (
  <Layout title={title}>
    <IconButtonPage title={title}>
      <IconButton
        icon={PURCHASE}
        text="Buying"
        href="/onboarding/buying"
        trackingEvent="discover-getstarted-buying-clicked"
      />
      <IconButton
        icon={REFINANCE}
        text="Refinancing"
        href={`${my}/onboarding/refinance`}
        trackingEvent="discover-getstarted-refinancing-clicked"
      />
    </IconButtonPage>
  </Layout>
);

export default GetStarted;
